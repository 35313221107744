<template>
  <div
    ref="elementNode"
    class="px-2 pt-2 data-[inactive='false']:fixed data-[inactive='false']:inset-0 data-[inactive='false']:z-40 data-[inactive='false']:bg-base-2 data-[inactive='false']:pb-16 sm:static sm:z-20 sm:m-0 sm:w-full sm:pb-2 data-[inactive='false']:sm:static data-[inactive='false']:sm:z-20 data-[inactive='false']:sm:m-0 data-[inactive='false']:sm:w-full data-[inactive='false']:sm:pb-2"
    :data-inactive="inactive === true ? true : false"
  >
    <slot></slot>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";

interface Props {
  inactive?: boolean;
}

defineProps<Props>();

const elementNode = ref<HTMLElement>();
defineExpose({ elementNode });
</script>
